import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import LoadingPage from './pages/LoadingPage/LoadingPage';

const FakeEvents = lazy(() => import('./components/FakeEvents'));
const isDev = import.meta.env.MODE === 'development';
const root = document.getElementById('course-study-tool')!;

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingPage />}>
      {isDev && <FakeEvents />}
      <App />
    </Suspense>
  </React.StrictMode>,
  root
);
