import React, { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const AITutor = lazy(() => import('pages/AITutor/AITutor'));
const ErrorPage = lazy(() => import('pages/ErrorPage/ErrorPage'));
const LoadingPage = lazy(() => import('pages/LoadingPage/LoadingPage'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AITutor authTokenRefreshBufferMinutes={5} authTokenTimeoutSeconds={15} />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/loading',
    element: <LoadingPage />,
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
  {
    path: '*',
    element: (
      <div>
        <p>What did you do!</p>
      </div>
    ),
  },
]);

const App = () => <RouterProvider router={router} />;

export default App;
